export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~3],
		"/animate-a-bell-curve": [4],
		"/animated-bar-chart": [5],
		"/basics/bar-chart": [6],
		"/basics/basic-shapes": [7],
		"/basics/density-plot": [8],
		"/basics/donut-chart": [9],
		"/basics/drawing-svg": [10],
		"/basics/dual-axis": [11],
		"/basics/line-chart": [12],
		"/basics/line-gradient": [13],
		"/basics/scatter-chart-2": [15],
		"/basics/scatter-chart": [14],
		"/basics/stacked-bar-chart": [16],
		"/basics/svelte-5-d3-example": [17],
		"/beeswarm-chart": [18],
		"/comingsoon": [19],
		"/creating-data": [20],
		"/dna-gel": [21],
		"/du-bois-05": [22],
		"/login": [~23],
		"/maps/svelte-threejs-example": [24],
		"/maps/us-heatmap": [25],
		"/pro-tutorials": [29],
		"/pro": [~26,[2]],
		"/pro/sankey-chart": [27,[2]],
		"/pro/us-heatmap": [28,[2]],
		"/register": [~30],
		"/us-heatmap": [31],
		"/visualizations/area-chart": [32],
		"/visualizations/sankey-chart": [33]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';